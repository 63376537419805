import {
  Button,
  RadioGroup,
  useCallbackRef,
} from "@faxi/web-component-library";
import { FormField, FormRef, useFormRefValues } from "@faxi/web-form";
import {
  APP_URI,
  FormSteps,
  StorageKeys,
  YesNo,
  getYesNoOptions,
} from "common";
import {
  FormActions,
  FormStep,
  NumberInputField,
  RequiredLabel,
} from "components";
import parse from "html-react-parser";
import { useAnalytics } from "providers";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { storageService } from "services";
import { parseLokaliseNewLine, validations } from "utils";

import { OptionModalLabel } from "../Step4/components";
import { StyledStepPage } from "./Step2-SingleWithChildren.styled";
import {
  onAnyChildBenefitsChange,
  onHaveChildrenUnder18Change,
  onPayForChildcareChange,
} from "./utils";

const Step2SingleWithChildren: FC = () => {
  const [form, formRef] = useCallbackRef<FormRef>();
  const { t } = useTranslation();

  const history = useHistory();
  const analytics = useAnalytics();

  const formValues =
    useFormRefValues(
      form,
      "haveChildrenUnder18",
      "payForChildcare",
      "childcareCosts",
      "anyChildBenefits",
      "childcareAllowance",
      "numOfChildren",
    ) ?? {};

  const handleTrackEvent = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: history.location.pathname,
    });
  }, [history.location?.pathname, analytics]);

  const handleSubmit = useCallback(async () => {
    storageService.setItem(
      StorageKeys.COMPLETED_STEPS_STORAGE_KEY,
      FormSteps.STEP_2_1,
    );

    handleTrackEvent();

    history.push(APP_URI.STEP_3);
  }, [handleTrackEvent, history]);

  const shouldRenderNumOfChildren =
    formValues.haveChildrenUnder18 === YesNo.YES;

  const shouldRenderPayForChildcare =
    shouldRenderNumOfChildren &&
    +formValues.numOfChildren > 0 &&
    +formValues.numOfChildren < 10;

  const shouldRenderChildcareCosts =
    shouldRenderPayForChildcare && formValues.payForChildcare === YesNo.YES;

  const shouldRenderAnyChildBenefits =
    shouldRenderChildcareCosts && +formValues.childcareCosts > 0;

  const shouldRenderChildcareAllowance =
    shouldRenderAnyChildBenefits && formValues?.anyChildBenefits === YesNo.YES;

  return (
    <StyledStepPage>
      <FormStep ref={formRef} onSubmit={handleSubmit}>
        <FormField
          name="haveChildrenUnder18"
          options={getYesNoOptions(t)}
          component={RadioGroup}
          orientation="column"
          onChange={onHaveChildrenUnder18Change}
          validate={validations(t).selectAnyValue}
          label={
            <OptionModalLabel
              label={
                <RequiredLabel>
                  {t("materialstatus_question_under18")}
                </RequiredLabel>
              }
              modalProps={{
                title: "",
                description: t("materialstatus_question_under18_modal"),
              }}
              labelClassName="field-modal-label"
              labelPosition="right"
            />
          }
        />

        {shouldRenderNumOfChildren && (
          <FormField
            name="numOfChildren"
            label={t("materialstatus_question_howmanychildren")}
            validate={validations(t).betweenOneAndNine}
            component={NumberInputField}
            prefix=" "
            placeholder=" "
          />
        )}

        {shouldRenderPayForChildcare && (
          <FormField
            name="payForChildcare"
            options={getYesNoOptions(t)}
            component={RadioGroup}
            validate={validations(t).selectAnyValue}
            orientation="column"
            onChange={onPayForChildcareChange}
            label={
              <OptionModalLabel
                label={
                  <RequiredLabel>
                    {t("materialstatus_question_childcare")}
                  </RequiredLabel>
                }
                modalProps={{
                  title: "",
                  description: parseLokaliseNewLine(
                    t("materialstatus_question_benefits_howmuch_modal"),
                  ),
                }}
                labelClassName="field-modal-label"
                labelPosition="right"
              />
            }
          />
        )}

        {shouldRenderChildcareCosts && (
          <FormField
            name="childcareCosts"
            label={
              <OptionModalLabel
                label={
                  <RequiredLabel>
                    {t("materialstatus_question_childcare_howmuch")}
                  </RequiredLabel>
                }
                modalProps={{
                  title: "",
                  description: t(
                    "materialstatus_singlewchildren_question_howmuch",
                  ),
                }}
                labelClassName="field-modal-label"
                labelPosition="right"
              />
            }
            validate={validations(t).positiveNumber}
            component={NumberInputField}
          />
        )}

        {shouldRenderAnyChildBenefits && (
          <FormField
            name="anyChildBenefits"
            options={getYesNoOptions(t)}
            component={RadioGroup}
            orientation="column"
            onChange={onAnyChildBenefitsChange}
            validate={validations(t).selectAnyValue}
            label={
              <OptionModalLabel
                label={
                  <RequiredLabel>
                    {t("materialstatus_question_benefits")}
                  </RequiredLabel>
                }
                modalProps={{
                  title: "",
                  description: parseLokaliseNewLine(
                    t("materialstatus_question_benefits_modal"),
                  ),
                }}
                labelClassName="field-modal-label"
                labelPosition="right"
              />
            }
          />
        )}

        {shouldRenderChildcareAllowance && (
          <FormField
            name="childcareAllowance"
            label={
              <OptionModalLabel
                label={
                  <RequiredLabel>
                    {t("materialstatus_question_benefits_receiving_monthly")}
                  </RequiredLabel>
                }
                modalProps={{
                  title: "",
                  description: parse(
                    t("materialstatus_question_benefits_howmuch_modal"),
                  ),
                }}
                labelClassName="field-modal-label"
                labelPosition="right"
              />
            }
            className="fc-step2__how-much"
            validate={validations(t).positiveNumber}
            component={NumberInputField}
          />
        )}

        <FormActions hasBackButton>
          <Button type="submit" disabled={!form?.syncFormValid}>
            {t("next")}
          </Button>
        </FormActions>
      </FormStep>
    </StyledStepPage>
  );
};

export default Step2SingleWithChildren;
